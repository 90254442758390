<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "../widget";
import CallAnalytics from "../callAnalytics.vue";
import CallStatusAnalytics from "../call-status-analytics.vue";
import DemographicCallStatusAnalytics from "../demographic-call-status-analytics.vue";
import Transaction from "../transaction";
import Filters from "../filters.vue";
import appConfig from "@/app.config";
import moment from "moment";
import AddressCount from "../addressCount.vue";
import OptinStatus from "../optinStatus.vue";
import CallStatuses from "../call-statuses.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    CallStatusAnalytics,
    Transaction,
    Filters,
    CallAnalytics,
    DemographicCallStatusAnalytics,
    AddressCount,
    OptinStatus,
    CallStatuses
},
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      summary: {},
      previousSummary: {},
      bestSummary: {},
      state_summary: {},
      filters: {},
      totalDoctorsAddresses: 0,
      totalDoctorsContacted: 0,
      totalDoctorsAllocated: 0,
      call_duration_buckets: {},
      call_analytics_filter: {},
      reload: 0,
      params: {},
      doctors_contacted: 0,
      loading: true,
    };
  },
  methods: {
    init() {
      // this.loading = true;
      this.getCallSummery();
      this.getDoctorsCount();
      this.getCallDurationBuckets();
      this.getStateCallSummery();
      // this.getAddressCount();
      // this.loading = false;
    },
    async getCallSummery() {
      this.summary = {};
      this.previousSummary = {};
      this.bestSummary = {};
      try {
        let params = this.getParams({});
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/callStatusesSummary",
          {
            params: params,
          }
        );
        this.summary = response.data.call_statuses_summary;
        this.previousSummary = response.data.previous_call_statuses_summary;
        this.bestSummary = response.data.best_call_statuses_summary;
      } catch (err) {
        console.log(err);
      }
    },
    async getStateCallSummery() {
      this.state_summary = {};
      try {
        let params = this.getParams({});
        let call_status = this.call_analytics_filter.call_statuses
          ? this.call_analytics_filter.call_statuses.map((obj) => obj.title)
          : null;
        if (call_status != null) {
          if(Object.keys(appConfig.sub_statuses).some(r => call_status.includes(r.replace(' ','_')))){
            params.call_sub_statuses = call_status;
          }else{
            params.call_statuses = call_status;
          }
        }
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/stateCallsSummary",
          {
            params: params,
          }
        );
        this.state_summary = response.data.state_call_summary;
      } catch (err) {
        console.log(err);
      }
    },
    async getDoctorsCount() {
      try {
        let params = this.getParams({});
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/doctors",
          {
            params: params,
          }
        );
        let data = response.data;
        this.totalDoctorsAddresses = data.totalDoctorsAddresses;
        this.totalDoctorsContacted = data.totalDoctorsContacted;
        this.totalDoctorsAllocated = data.totalDoctorsAllocated;
      } catch (err) {
        console.log(err);
      }
    },
    async getCallDurationBuckets() {
      try {
        let params = this.getParams({});
        let call_status = this.call_analytics_filter.call_statuses
          ? this.call_analytics_filter.call_statuses.map((obj) => obj.title)
          : null;
        if (call_status != null) {
          if(Object.keys(appConfig.sub_statuses).some(r => call_status.includes(r.replace(' ','_')))){
            params.call_sub_statuses = call_status;
          }else{
            params.call_statuses = call_status;
          }
        }
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/callDurationsBuckets",
          {
            params: params,
          }
        );
        this.call_duration_buckets = response.data.call_durations;
      } catch (err) {
        console.log(err);
      }
    },
    getParams(params) {
      let project_ids = this.filters.project
        ? this.filters.project.map((obj) => obj.id)
        : {};
      if (project_ids && project_ids.length > 0) {
        params.project_ids = project_ids;
      }
      let specialities = this.filters.speciality
        ? this.filters.speciality.map((obj) => obj.title)
        : {};
      if (specialities && specialities.length > 0) {
        params.specialities = specialities;
      }

      let opted_in = this.filters.opted_in ? this.filters.opted_in : null;
      if (opted_in && opted_in != null) {
        params.opted_in = this.filters.opted_in ? this.filters.opted_in : false;
      }

      let from_date =
        this.filters.daterange && this.filters.daterange.length > 0
          ? this.getFormatedDate(this.filters.daterange[0])
          : null;
      if (from_date) {
        params.from_date = from_date;
      }
      let to_date =
        this.filters.daterange &&
        this.filters.daterange.length > 0 &&
        this.filters.daterange[1]
          ? this.getFormatedDate(this.filters.daterange[1])
          : null;
      if (to_date) {
        params.to_date = to_date;
      }

      let states = this.filters.state
        ? this.filters.state.map((obj) => obj.name)
        : {};
      if (states && states.length > 0) {
        params.states = states;
      }

      let cities = this.filters.city
        ? this.filters.city.map((obj) => obj.name)
        : {};
      if (cities && cities.length > 0) {
        params.cities = cities;
      }
      this.params = {};
      this.params = params;
      return params;
    },
    getFormatedDate(date) {
      try {
        if (date != null) {
          return moment(date).format("yyyy-MM-DD");
        }
        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    setFilters(v) {
      this.filters = v;
    },
    setAnalyticsFilters(v) {
      this.call_analytics_filter = v;
    },
  },
  mounted() {
this.init();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.init();
      },
    },
    call_analytics_filter: {
      deep: true,
      handler() {
        this.getCallDurationBuckets();
        this.getStateCallSummery();
      },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <filters @filters="setFilters" />
    <div class="row">
      <div class="col-xl-8">
        <Stat
          :optedIn="filters.opted_in"
          :summary="summary"
          :previousSummary="previousSummary"
          :totalDoctorsContacted="totalDoctorsContacted"
          :totalDoctorsAllocated="totalDoctorsAllocated"
        />
        <CallAnalytics
          :buckets="call_duration_buckets"
          @analytics_filters="setAnalyticsFilters"
        />
      </div>
      <div class="col-xl-4">
        <CallStatusAnalytics :summary="summary" :bestSummary="bestSummary" />
        <template v-if="$store.state.authfack.user.role != 'AnalyticsSuperAdmin' && $store.state.authfack.user.username == 'Sun Pharma'">
          <AddressCount :totalDoctorsAddresses="totalDoctorsAddresses"></AddressCount>
          <OptinStatus :filters="params"></OptinStatus>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <Transaction :filters="params" />
      </div>
      <div class="col-lg-6">
        <DemographicCallStatusAnalytics :summary="state_summary" />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <CallStatuses :summary="summary" />
      </div>
    </div>
  </Layout>
</template>
