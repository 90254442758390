<script>
/**
 * Call Statuses component
 */
// import appConfig from "@/app.config";

export default {
   props: ["summary"],
   data() {
      return {
         callStatusesData: [],
         totalRows: 1,
         currentPage: 1,
         perPage: 10,
         pageOptions: [5, 10, 25, 50],
         filter: null,
         filterOn: [],
         sortBy: "total_calls",
         sortDesc: true,
         fields: [
            { key: "product_call_status", sortable: true, label: "Call Status" },
            { key: "sum_of_calls", sortable: true, label: "Percentage" },
         ]
      };
   },
   watch: {
      summary: {
         deep: true,
         handler(v) {
            if (v && v.length > 0) {
               this.loadData(v);
            }
         },
      }
   },
   computed: {
      /**
       * Total no. of records
       */
      rows() {
         return this.callStatusesData.length;
      },
   },
   mounted() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.$store.commit("setRole",user.role);
   },
   methods: {
      /**
       * Search the table data with search input
       */
      onFiltered(filteredItems) {
         // Trigger pagination to update the number of buttons/pages due to filtering
         this.totalRows = filteredItems.length;
         this.currentPage = 1;
      },
      loadData(v) {
         if (v) {
            this.totalCalls = 0;
            this.callStatusesData = v;
            this.totalCalls += this.callStatusesData.map(d => d.total_calls).reduce((a,b) => a+b,0);
            this.sum_of_calls = (((this.callStatusesData.map((f) => f.total_calls)) / this.totalCalls) * 100).toFixed(2);
            this.totalRows = this.callStatusesData.length;
         }
      },
      getPercentage(i) {
         if (i != null && i != '') {
            return ((i / this.totalCalls) * 100).toFixed(2);
         }
         return 0
      }
   },
};
</script>

<template>
   <div class="card" v-if="$store.state.role == 'PharmaIndustry'">
      <div class="card-body">
         <h4 class="card-title mb-4">Call Statuses</h4>
         <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
               <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                     Show&nbsp;
                     <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
               </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
               <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                     Search:
                     <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
               </div>
            </div>
            <!-- End search -->
         </div>
         <div class="table-responsive">
            <b-table :items="callStatusesData" :fields="fields" responsive="sm" :per-page="perPage"
               :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
               :filter-included-fields="filterOn" @filtered="onFiltered">
               <template v-slot:cell(product_call_status)="row">
                  {{ row.item.product_call_status }}
               </template>
               <template v-slot:cell(sum_of_calls)="row">
                  <span>{{ getPercentage(row.item.total_calls) }} %</span>
               </template>
            </b-table>
         </div>
         <div class="row">
            <div class="col">
               <div class="dataTables_pa ginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                     <!-- pagination -->
                     <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
