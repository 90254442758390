<script>
// import appConfig from "@/app.config";

export default {
    props: [
    "totalDoctorsAddresses",
  ],
  data() {
    return {
    };
  },
  methods: {
    
  },
  mounted() {
    
  },
  watch: {
    filters: {
      deep: true,
      handler() {
      },
    },
  },
};
</script>
<template>
    <div class="card">
        <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center">
                <h4 class="card-title" style="align-self: center;"> Addresses Given</h4>
                <div class="text-primary"><i class="mdi mdi-home-search" style="font-size: 25px;"></i></div>
            </div>
            <h3 class="mb-0">{{ totalDoctorsAddresses }}</h3>
        </div>
    </div>
</template>

