<script>
// import appConfig from "@/app.config";
import ECharts from "vue-echarts";
import "echarts/lib/chart/pie";
import Switches from "vue-switches";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/grid";
import "echarts/lib/component/axis";
import _ from "lodash";

export default {
  props: ["summary"],
  components: {
    "v-chart": ECharts,
    Switches,
  },
  data() {
    return {
      cityData: false,
      chartOptions: {
        visualMap: {
          show: true,
        },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.data.name}<br />
                Total Calls : ${params.data.value}<br />
                Total Duration : ${params.data.total_call_duration} Sec`;
          },
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: [],
          textStyle: {
            color: "#6e0909",
          },
        },
        // toolbox: [
        //   {
        //     feature: {
        //       restore: {
        //         title: "restore"
        //       },
        //       saveAsImage: { title: 'Save', show: true },
        //     }
        //   }
        // ],
        color: [
          "#001558",
          "#0E2B86",
          "#1537A4",
          "#2A4DBA",
          "#2351DE",
          "#325DE5",
          "#3D68EE",
          "#4670F3",
          "#5E84F9",
          "#7193FD",
          "#9AB3FF",
          "#BECEFE",
        ],
        series: [
          {
            name: "Total sales",
            type: "pie",
            radius: "70%",
            center: ["65%", "55%"],
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      total_calls: 0,
      key: 0,
      counts: 0,
      allData: {},
    };
  },
  watch: {
    summary: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    cityData() {
      this.loadData();
    },
  },
  methods: {
    getRandomColor() {
      let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
      return color;
    },
    loadData() {
      this.chartOptions.series[0].data = [];
      const data = _.cloneDeep(this.summary);
      if(typeof data.length == 'undefined'){
        return;
      }
      this.counts = 0;
      if (this.cityData == false) {
        const compressed = data.reduce((a, c) => {
          const dateIndex = a.findIndex(
            (o) =>
              o.state === c.state &&
              (c.state != null || c.state != "null" || c.state != "")
          );
          if (dateIndex !== -1) a[dateIndex].value += c.total_calls;
          if (dateIndex !== -1)
            a[dateIndex].total_call_duration += parseInt(c.total_call_duration);
          else
            a.push({
              state: c.state,
              name: c.state,
              value: c.total_calls,
              total_call_duration: parseInt(c.total_call_duration),
            });
          return a;
        }, []);
        compressed.sort(function (a, b) {
          return b.value - a.value;
        });
        compressed.forEach((f, i) => {
          // this.chartOptions.color.push(this.getRandomColor());
          if (i < 11) {
            this.chartOptions.series[0].data.push(f);
          }
        });
      } else {
        const compressed = data.reduce((a, c) => {
          const dateIndex = a.findIndex(
            (o) =>
              o.city === c.city &&
              (c.city != null || c.city != "null" || c.city != "")
          );
          if (dateIndex !== -1) a[dateIndex].value += c.total_calls;
          if (dateIndex !== -1)
            a[dateIndex].total_call_duration += parseInt(c.total_call_duration);
          else
            a.push({
              city: c.city,
              name: c.city,
              value: c.total_calls,
              total_call_duration: parseInt(c.total_call_duration),
            });
          return a;
        }, []);
        compressed.sort(function (a, b) {
          return b.value - a.value;
        });
        compressed.forEach((f, i) => {
          // this.chartOptions.color.push(this.getRandomColor());
          if (i < 11) {
            this.chartOptions.series[0].data.push(f);
          }
        });
      }
      // this.chartOptions.series[0].data = this.chartOptions.series[0].data.sort((a,b) => parseInt(b.value) - parseInt(a.value))
      // let othersIndex = this.chartOptions.series[0].data.findIndex(f => f.name == 'Others');
      // if(othersIndex){
      //   let others = this.chartOptions.series[0].data[othersIndex];
      //   this.chartOptions.series[0].data.splice(othersIndex,1);
      //   this.chartOptions.series[0].data.push(others);
      // }
      this.chartOptions.legend.data = this.chartOptions.series[0].data.map(
        (obj) => obj.name
      );
      this.key += 1;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <h4 class="card-title">Demographics Analytics</h4>
        <div class="d-flex" style="float: right">
          <label class="mr-2">City</label>
          <div class="mb-3 pt-2">
            <switches
              v-model="cityData"
              type-bold="true"
              color="success"
              class="mb-0"
            ></switches>
          </div>
        </div>
      </div>
      <v-chart :options="chartOptions" autoresize :key="key" />
    </div>
  </div>
</template>
