<script>
/**
 * Revenue Analytics component
 */
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

export default {
  props: ["buckets"],
  components: {
    Multiselect,
  },
  data() {
    return {
      series: [
        {
          name: "Total Calls",
          type: "column",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: true,
          },
        },
        stroke: {
          width: [3],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: appConfig.duration_labels,
      },
      key: 0,
      filters: {
        call_statuses: null,
        call_sub_statuses: null,
      },
      statuses: [],
    };
  },
  methods: {
    async getCallStatuses() {
      try {
        if(this.$store.state.authfack.user.role != 'AnalyticsSuperAdmin') {
          const response = await this.$http.get(
            appConfig.api_base_url + "/telecallers/callSubStatuses"
          );
          this.statuses = response.data.call_statuses;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/telecallers/callStatuses"
          );
          this.statuses = response.data.call_statuses;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getCallStatuses();
  },
  watch: {
    buckets: {
      deep: true,
      handler(v) {
        this.series[0].data = Object.values(v[0]);
        this.key += 1;
      },
    },
    filters: {
      deep: true,
      handler(v) {
        this.$emit("analytics_filters", v);
      },
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">



      <div class="d-flex justify-content-between">
        <div><h4 class="card-title mb-0">Call Analytics</h4></div>
        <div class="w-25">
          <label class="mb-0">Call Status</label>
          <multiselect
            v-model="filters.call_statuses"
            :close-on-select="false"
            :show-labels="false"
            :options="statuses"
            track-by="title"
            label="display_key"
            :multiple="true">
          </multiselect>
        </div>
      </div>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="280"
          dir="ltr"
          :series="series"
          :options="chartOptions"
          :key="key"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
