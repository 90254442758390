<script>
import appConfig from "@/app.config";

/**
 * Widget Component
 */
export default {
  props: [
    "summary",
    "previousSummary",
    "totalDoctorsContacted",
    "totalDoctorsAllocated",
    "optedIn",
  ],
  watch: {
    totalDoctorsContacted() {
      this.statsData[1].value =
        this.totalDoctorsContacted > 0 ? this.totalDoctorsContacted : "0";
    },
    totalDoctorsAllocated() {
      this.statsData[1].total_value =
          this.totalDoctorsAllocated > 0 ? this.totalDoctorsAllocated : "0";
    },
    summary: {
      deep: true,
      handler(v) {
        this.setTotalCalls(v);
      },
    },
  },
  created() {
    this.statsData[1].value = this.totalDoctorsContacted;
  },
  data() {
    return {
      statsData: [
        {
          title: "Total Calls",
          icon: "mdi mdi-call-made",
          value: 0,
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          title: "Total Doctors Contacted",
          icon: "mdi mdi-doctor",
          value: 0,
          total_value: "0",
          subtext: "Doctors Allocated",
        },
        {
          title: "Avg. Successful Call Duration",
          icon: "mdi mdi-timelapse",
          value: 0,
          total_value: 0,
          subtext: "Avg. Call Duration",
        },
      ],
      present_total_calls: 0,
      previous_total_calls: 0,
      total_success_calls: 0,
      total_duration: 0,
      loader: true,
    };
  },
  methods: {
    setTotalCalls(v) {
      this.present_total_calls = 0;
      this.previous_total_calls = 0;
      this.statsData[0].value = 0;
      this.statsData[0].subvalue = 0;
      this.total_duration = 0;
      if (v && v.length > 0) {
        v.forEach((d, di) => {
          if (this.previousSummary && this.previousSummary.length > 0) {
            this.previous_total_calls += this.previousSummary[di]
              ? parseInt(this.previousSummary[di].total_calls)
              : 0;
          }
          this.present_total_calls += parseInt(d.total_calls);
          this.statsData[0].value += parseInt(d.total_calls);
          this.total_duration += parseFloat(d.total_call_duration);
          if (d.product_call_status === appConfig.SUCCESS_CALL_STATUS) {
            this.total_success_calls = parseInt(d.total_calls);
            this.statsData[2].value = parseFloat(d.total_call_duration);
          }
        });
      }
      this.statsData[2].value = (
        this.statsData[2].value / this.total_success_calls
      ).toFixed(1);
      this.statsData[2].value =
        (!isNaN(this.statsData[2].value) ? this.statsData[2].value : "0") +
        " sec";

      this.statsData[2].total_value = (
        this.total_duration / this.present_total_calls
      ).toFixed(1);
      this.statsData[2].total_value =
        (!isNaN(this.statsData[2].total_value)
          ? this.statsData[2].total_value
          : "0") + " sec";

      this.statsData[0].subvalue =
        this.previous_total_calls !== 0
          ? (
              ((this.present_total_calls - this.previous_total_calls) /
                this.previous_total_calls) *
              100
            ).toFixed(2)
          : "0";
      this.statsData[0].subvalue = !isNaN(this.statsData[0].subvalue)
        ? this.statsData[0].subvalue
        : "0";
    },
    secondsToTime(e) {
      var h = Math.floor(e / 3600)
          .toString()
          .padStart(2, "0"),
        m = Math.floor((e % 3600) / 60)
          .toString()
          .padStart(2, "0"),
        s = Math.floor(e % 60)
          .toString()
          .padStart(2, "0");

      return h + "hr " + m + "m " + s + "sec ";
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statsData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-15 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon}`" style="font-size: 25px"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <!--<p>{{data.avg_call_duration}}</p>-->
          <div class="">
            <template v-if="data.subvalue">
              <span
                class="badge font-size-11"
                :class="
                  data.subvalue > 0 ? 'badge-soft-success' : 'badge-soft-danger'
                "
              >
                <i
                  class="mdi"
                  :class="data.subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'"
                ></i>
                {{ data.subvalue }} %
              </span>
              <span class="text-muted ml-2">{{ data.subtext }}</span>
            </template>
            <template v-if="data.total_value">
              <template v-if="index == 1">
                <span
                  v-if="optedIn == undefined || optedIn == false"
                  class="badge font-size-13"
                >
                  <i class="mdi"></i>
                  {{ data.total_value }}
                </span>
                <span
                  v-if="optedIn == undefined || optedIn == false"
                  class="text-muted ml-2"
                  >{{ data.subtext }}</span
                >
              </template>
              <template v-else>
                <span class="badge font-size-13">
                  <i class="mdi"></i>
                  {{ data.total_value }}
                </span>
                <span class="text-muted ml-2">{{ data.subtext }}</span>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
